import { useCallback, useMemo, useState } from "react";
import useSWR from "swr";
import { Endpoints } from "../api/endpoints";
import {
  ProfileFormData,
  UseProfileBriefInfo,
  UseProfileData,
  UseProfileManager,
} from "../types/Profile";
import { mutateData } from "../api/api";
import { useAuth } from "./Auth";
import { Roles } from "../constants/roles";
import { usePhoneCodes } from "../utils/PhoneCodes";

export const useProfileBriefInfo = (): UseProfileBriefInfo => {
  const { isLoggedIn } = useAuth();
  const { data: profile, error: profileError } = useSWR(
    isLoggedIn ? Endpoints.profileBriefInfo : null
  );

  const loading = useMemo(
    () => !profile && !profileError,
    [profile, profileError]
  );

  const isBasfUser = useMemo(() => {
    if (loading || !profile?.roles) return false;
    return profile?.roles?.includes(Roles.basfUser);
  }, [loading, profile?.roles]);

  return {
    profile,
    loading,
    profileError,
    isBasfUser,
  };
};

export const useProfileData = (): UseProfileData => {
  const { isLoggedIn } = useAuth();
  const {
    data: userData,
    error: userDataError,
    mutate: mutateProfile,
  } = useSWR(isLoggedIn ? Endpoints.profileData : null);
  const { code } = usePhoneCodes();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loading = useMemo(
    () => !userData && !userDataError,
    [userData, userDataError]
  );

  const parsedUserData = useMemo(() => {
    if (!userData) return null;
    return {
      ...userData,
      phone: userData.phone.slice(code.length),
      city: userData?.city,
      region: userData.city?.region,
    };
  }, [userData, code]);

  const parseDataForRequest = useCallback((formData: ProfileFormData) => {
    const data = { ...formData };
    const filtered = Object.keys(data)
      .filter((key) => !!key)
      .reduce((item: any, key) => {
        // TODO: refactor object management to remove region field
        delete item["region"];
        item[key as keyof ProfileFormData] = data[key as keyof ProfileFormData];
        return item;
      }, {});
    return {
      ...filtered,
      city: filtered.city?.id,
    };
  }, []);

  const editProfile = useCallback(
    async (formData: ProfileFormData) => {
      setIsLoading(true);
      const data = parseDataForRequest(formData);
      const url = Endpoints.editProfile;

      return mutateData("put", url, data)
        .then(async () => {
          await mutateProfile();
          return {
            success: true,
          };
        })
        .catch((e) => {
          return e?.response?.data;
        })
        .finally(() => setIsLoading(false));
    },
    [mutateProfile, parseDataForRequest]
  );

  return {
    userData: parsedUserData,
    loading: loading,
    userDataError,
    isLoading,
    editProfile,
  };
};

export const useProfileManager = (): UseProfileManager => {
  // fetch if locale is EE only
  const { data: profileManager, error: profileManagerError } = useSWR(
    Endpoints.profileManager
  );

  const loading = useMemo(
    () => !profileManager && !profileManagerError,
    [profileManager, profileManagerError]
  );

  return {
    profileManager,
    loading,
    profileManagerError,
  };
};
