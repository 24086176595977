import { makeStyles, Theme } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme: Theme) => ({
  PopupHeaderText: {
    color: theme.palette.primary.main,
    padding: "12px 38px 12px 0",
    letterSpacing: "0.02em",
    display: "flex",
    alignItems: "center",
    borderRadius: 0,
    cursor: "pointer !important",
    justifyContent: "flex-start",
    textTransform: "none",
    "&.MuiButton-root.Mui-disabled": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 38px 0 0",
    },
  },
  PopHeaderIcon: {
    marginRight: "16px",
  },
  PopupText: {
    paddingTop: theme.spacing(0.75),
    lineHeight: "18px",
    fontWeight: 500,
  },
  noTouch: {
    pointerEvents: "none",
  },
}));
