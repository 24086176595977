import clsx from "clsx";
import { useMemo } from "react";
import { useStyles } from "./ArrowIcon.styles";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrowFilter.svg";

interface IconProps {
  open: boolean;
  isBlue: boolean;
  isWhite?: boolean;
}
const ArrowRotateIcon: React.FC<IconProps> = ({
  open,
  isBlue,
  isWhite = false,
}) => {
  const classes = useStyles({ isBlue, isWhite });
  const arrowClasses = useMemo(() => {
    return clsx({
      [classes.arrow]: true,
      [classes.arrowDown]: !open,
      [classes.arrowUp]: open,
    });
  }, [classes.arrow, classes.arrowDown, classes.arrowUp, open]);

  return <ArrowIcon className={arrowClasses} />;
};
export default ArrowRotateIcon;
