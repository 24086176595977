import { useTranslation } from "react-i18next";
import { lazy, useMemo } from "react";
import FullWidthLayout from "../components/FullWidthLayout/FullWidthLayout";

import { Route } from "../types/Common";
import { Roles } from "./roles";

const ApplicationForm = lazy(
  () => import("../components/ApplicationForm/ApplicationForm")
);
const Home = lazy(() => import("../containers/Home/Home"));
const FAQ = lazy(() => import("../containers/FAQ/FAQ"));
const Terms = lazy(() => import("../containers/Terms/Terms"));
const Marketing = lazy(() => import("../containers/Marketing/Marketing"));
const Privacy = lazy(() => import("../containers/PrivacyPolicy/PrivacyPolicy"));
const Profile = lazy(() => import("../containers/MyProfile/MyProfile"));
const Invoice = lazy(() => import("../containers/Invoice/Invoice"));
const MyPointsHistory = lazy(
  () => import("../containers/MyPointsHistory/MyPointsHistory")
);
const ChangePassword = lazy(
  () => import("../containers/ChangePassword/ChangePassword")
);
const Login = lazy(() => import("../containers/Login/Login"));
const SignUp = lazy(() => import("../containers/SignUp/SignUp"));
const ForgotPassword = lazy(
  () => import("../containers/ForgotPassword/ForgotPassword")
);
const CreatePassword = lazy(
  () => import("../containers/CreatePassword/CreatePassword")
);
const Landing = lazy(() => import("../containers/Landing/Landing"));
const DistributorSignUp = lazy(
  () => import("../containers/DistributorSignUp/DistributorSignUp")
);
const ActivateDistributor = lazy(
  () => import("../containers/ActivateDistributor/ActivateDistributor")
);
const Survey = lazy(() => import("../containers/Survey/Survey"));
const NotFound = lazy(() => import("../containers/NotFound/NotFound"));

export const pathMap = {
  bg: {
    home: "/landing",
    faq: "/faq",
    terms: "/terms",
    marketingRules: "/marketing-rules",
    privacyPolicy: "/privacy-policy",
    profile: "/profile",
    invoice: "/invoice",
    myPointsHistory: "/points-history",
    changePassword: "/change-password",
    login: "/login",
    signup: "/signup",
    createPassword: "/auth/create-password",
    forgotPassword: "/forgot-password",
    landing: "/",
    distributorSignUp: "/signup-distributor",
    activateDistributor: "/auth/distributor/activate",
    survey: "/survey/:surveyId",
    // unavailable
    prizes: "/prizes/:category?",
    prizeItem: "/prizes/:prizeName/:prizeId",
    products: "/products/:category?",
    favorites: "/favorites",
    cart: "/cart",
    checkout: "/checkout",
    myInvoices: "/my-invoices",
    myOrders: "/my-orders",
    noPrize: "/no-prize",
    about: "/about",
    applicationForm: "/application-form",
  },
};

export const usePaths = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const paths = useMemo(() => pathMap[language as "bg"], [language]);

  return paths;
};

export const useRoutes = (): Route[] => {
  const paths = usePaths();

  return [
    {
      path: paths.home,
      component: Home,
      disableBorder: true,
    },
    {
      path: paths.faq,
      component: FAQ,
      layout: FullWidthLayout,
    },
    {
      path: paths.terms,
      component: Terms,
      layout: FullWidthLayout,
      public: true,
    },
    {
      path: paths.privacyPolicy,
      component: Privacy,
      layout: FullWidthLayout,
      public: true,
    },
    {
      path: paths.marketingRules,
      component: Marketing,
      layout: FullWidthLayout,
      public: true,
    },
    {
      path: paths.profile,
      component: Profile,
    },
    {
      path: paths.invoice,
      component: Invoice,
      roles: [Roles.basfUser],
    },
    {
      path: paths.myPointsHistory,
      component: MyPointsHistory,
    },
    {
      path: paths.changePassword,
      component: ChangePassword,
    },
    {
      path: paths.login,
      component: Login,
      public: true,
      layout: FullWidthLayout,
      disableBorder: true,
    },
    {
      path: paths.signup,
      component: SignUp,
      public: true,
    },
    {
      path: paths.forgotPassword,
      component: ForgotPassword,
      public: true,
    },
    {
      path: paths.createPassword,
      component: CreatePassword,
      public: true,
    },
    {
      path: paths.landing,
      component: Landing,
      public: true,
      layout: FullWidthLayout,
      disableBorder: true,
    },
    {
      path: paths.distributorSignUp,
      component: DistributorSignUp,
      public: true,
    },
    {
      path: paths.activateDistributor,
      component: ActivateDistributor,
      public: true,
    },
    {
      path: paths.survey,
      layout: FullWidthLayout,
      component: Survey,
    },
    {
      path: paths.noPrize,
      component: NotFound,
      public: true,
    },
    {
      path: paths.applicationForm,
      component: ApplicationForm,
    },
    // unavailable
    // {
    //   path: paths.prizes,
    //   component: Prizes,
    //   layout: FullWidthLayout,
    // },
    // {
    //   path: paths.prizeItem,
    //   component: Prize,
    // },
    // {
    //   path: paths.products,
    //   component: Products,
    //   roles: [Roles.basfUser],
    // },
    // {
    //   path: paths.favorites,
    //   component: Favorites,
    // },
    // {
    //   path: paths.cart,
    //   component: Cart,
    // },
    // {
    //   path: paths.checkout,
    //   component: Checkout,
    // },
    // {
    //   path: paths.myInvoices,
    //   ...(language === "lv" && { altPath: "/mani-reķini" }),
    //   component: MyInvoices,
    //   roles: [Roles.basfUser],
    // },
    // {
    //   path: paths.myOrders,
    //   component: MyOrders,
    // },
    // {
    //   path: paths.about,
    //   component: About,
    //   layout: FullWidthLayout,
    //   disableBorder: true,
    // },
  ];
};
