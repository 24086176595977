import { useTranslation } from "react-i18next";

import { ReactComponent as BGFlag } from "./../assets/icons/bg-flag.svg";

const phoneCodes = {
  bg: { code: "+359", Icon: BGFlag },
};

export const usePhoneCodes = () => {
  const { i18n } = useTranslation();

  return phoneCodes[i18n.language as keyof typeof phoneCodes];
};
