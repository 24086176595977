import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Colors } from "../../constants/Style";

interface ArrowProps {
  isBlue: boolean;
  isWhite: boolean;
}

export const useStyles = makeStyles<Theme, ArrowProps>((theme: Theme) =>
  createStyles({
    arrow: {
      transition: ".3s all",
      color: (props) =>
        props.isBlue
          ? Colors.darkBlue
          : props.isWhite
          ? Colors.white
          : Colors.middleGrey,
      "& path": {
        fill: (props: { isBlue: any; isWhite: any }) =>
          props.isBlue
            ? Colors.darkBlue
            : props.isWhite
            ? Colors.white
            : Colors.middleGrey,
      },
    },
    arrowDown: {
      transform: "rotate(0deg)",
    },
    arrowUp: {
      transform: "rotate(180deg)",
    },
  })
);
