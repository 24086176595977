import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";

import {
  AppBar,
  Container,
  Grid,
  Toolbar,
  Hidden,
  IconButton,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
  Collapse,
  Typography,
} from "@material-ui/core";

import MobileNavMenu from "./MobileNavMenu/MobileNavMenu";
import NavMenu from "./NavMenu/NavMenu";
import Top from "./TopHeader/TopHeader";
import HeaderPopper from "./HeaderPopper/HeaderPopper";
import ProfileList from "./ProfileList/ProfileList";
import { useStyles } from "./Header.styles";
import FavoritesList from "./FavoritesList/FavoritesList";
import Points from "./Points/Points";
import { ActivePopperType } from "../../types/Components";
import MyBagList from "./MyBagList/MyBagList";
import { useCart } from "../../state/Cart";
import { disableBodyScroll, enableBodyScroll } from "../../utils/Common";
import { ActionIcon } from "./ActionIcon/ActionIcon";

import { useAuth } from "../../state/Auth";
import { useProfileData } from "../../state/Profile";
import { useUtag } from "../../state/Utag";
import { useTranslation } from "react-i18next";

const Header = ({ growMenu }: { growMenu: boolean }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const classes = useStyles();
  const popperRef = useRef<HTMLButtonElement>(null);
  const { showCartPopper, setShowCart } = useCart();
  const [hoveredIcon, setHoveredIcon] = useState<string>("");
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const [stopClickAway, setStopClickAway] = useState<boolean>(false);

  const { i18n } = useTranslation();
  const { isLoggedIn } = useAuth();
  const { userData } = useProfileData();
  const { setUtagCustomer } = useUtag();

  const name = useMemo(() => userData?.firstName, [userData?.firstName]);

  const displayCountdownHeader = false;
  const renderCountdownHeader = null;

  const handleIconHover = useCallback((type: string, hover: boolean) => {
    setHoveredIcon(hover ? type : "");
  }, []);

  const [activePopper, setActivePopper] = useState<ActivePopperType>("");

  const openProfilePopper = useMemo(
    () => !!anchorEl && activePopper === "profile",
    [activePopper, anchorEl]
  );

  const openLanguagePopper = useMemo(
    () => !!anchorEl && activePopper === "languages",
    [activePopper, anchorEl]
  );

  const openFavoritesPopper = useMemo(
    () => !!anchorEl && activePopper === "favorites",
    [activePopper, anchorEl]
  );

  const openMyBagPopper = useMemo(
    () => !!anchorEl && activePopper === "bag",
    [activePopper, anchorEl]
  );

  const handleIconClick = useCallback(
    (
      event:
        | React.MouseEvent<HTMLButtonElement>
        | React.MouseEvent<HTMLSpanElement, MouseEvent>,
      type: ActivePopperType
    ) => {
      if (!!popperRef?.current) {
        event.preventDefault();
        event.stopPropagation();

        if (anchorEl && type === activePopper) {
          isMobile && enableBodyScroll();
          setAnchorEl(null);
          setActivePopper("");
        } else if (
          (!!type && type !== activePopper) ||
          (!isClosed && type === activePopper)
        ) {
          if (isMobile) disableBodyScroll();
          const newAnchor =
            type === "languages"
              ? (event as React.MouseEvent<HTMLButtonElement>).currentTarget
              : popperRef?.current;
          setAnchorEl(newAnchor);
          setActivePopper(type);
          setIsClosed(false);
          openMobileMenu && setOpenMobileMenu(false);
        } else {
          setIsClosed(false);
        }
      }
    },
    [activePopper, anchorEl, isClosed, isMobile, openMobileMenu]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
    openMobileMenu && !stopClickAway && setOpenMobileMenu(false);
    enableBodyScroll();
    setShowCart(false);
    setIsClosed(true);
    setStopClickAway(false);
  }, [openMobileMenu, setShowCart, stopClickAway]);

  const toggleMobileMenu = useCallback(() => {
    if (!openMobileMenu) {
      setAnchorEl(null);
      disableBodyScroll();
      setActivePopper("");
    } else enableBodyScroll();
    setOpenMobileMenu((prev) => !prev);
    setStopClickAway(true);
  }, [openMobileMenu]);

  const closeExtras = useCallback(() => {
    if (!!anchorEl && !!activePopper) {
      setAnchorEl(null);
      setActivePopper("");
    }
  }, [activePopper, anchorEl]);

  useEffect(() => {
    if (showCartPopper) {
      setAnchorEl(popperRef?.current);
      setActivePopper("bag");
      disableBodyScroll();
    }
  }, [showCartPopper, onClose]);

  useEffect(() => {
    if (isLoggedIn && userData) {
      setUtagCustomer({
        customer_id: String(userData?.id),
        customer_email: userData?.email,
        customer_country: i18n?.language,
        customer_region: "europe",
        customer_city: userData?.city,
        customer_zip: userData?.postalCode,
        customer_organization: userData?.companyName,
      });
    }
  }, [i18n.language, isLoggedIn, setUtagCustomer, userData]);

  return (
    <>
      <Box
        className={clsx(classes.appbarFill, {
          [classes.appbarFillTop]: !isLoggedIn,
          [classes.appbarFillWTimer]: displayCountdownHeader,
        })}
      ></Box>
      <AppBar color="inherit" elevation={0} position="fixed">
        {renderCountdownHeader}
        <Top
          handleIconClick={handleIconClick}
          handleIconHover={handleIconHover}
          activePopper={activePopper}
          hoveredIcon={hoveredIcon}
          openLanguagePopper={openLanguagePopper}
          onClose={onClose}
          anchorEl={anchorEl}
        />
        <Collapse in={growMenu} timeout={1100}>
          <Toolbar classes={{ root: classes.toolbar }}>
            <Container disableGutters className={classes.fullHeight}>
              <Grid
                justifyContent="space-between"
                alignItems="center"
                container
                className={classes.fullHeight}
              >
                <Grid item className={classes.menuGrid}>
                  {isLoggedIn && (
                    <>
                      <Hidden smDown>
                        <NavMenu closeExtras={closeExtras} />
                      </Hidden>
                      <Hidden mdUp>
                        <MobileNavMenu
                          openMobileMenu={openMobileMenu}
                          toggleMobileMenu={toggleMobileMenu}
                          onClose={onClose}
                        />
                      </Hidden>
                    </>
                  )}
                </Grid>
                <Grid item>
                  <Grid justifyContent="flex-end" alignItems="center" container>
                    {isLoggedIn && (
                      <Hidden smDown>
                        <Grid item>
                          <Points />
                        </Grid>
                      </Hidden>
                    )}
                    <Box className={classes.poppersContent}>
                      {isLoggedIn && (
                        <Box
                          className={classes.profileBox}
                          onClick={(e) => handleIconClick(e, "profile")}
                          onMouseEnter={() => handleIconHover("profile", true)}
                          onMouseLeave={() => handleIconHover("profile", false)}
                        >
                          {!isXS && (
                            <Typography
                              variant="h5"
                              color="primary"
                              className={classes.profileName}
                            >
                              {name}
                            </Typography>
                          )}
                          <ActionIcon
                            isActive={
                              (activePopper === "profile" &&
                                openProfilePopper) ||
                              hoveredIcon === "profile"
                            }
                            type={"user"}
                            handleIconClick={(e) =>
                              handleIconClick(e, "profile")
                            }
                            handleHover={() => {}}
                            badge={false}
                            noSpacing
                          />
                        </Box>
                      )}
                      <IconButton
                        id="popper-ref"
                        ref={popperRef}
                        style={{ padding: "24px 0" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </Collapse>
        {isLoggedIn && (
          <>
            <HeaderPopper
              open={openMyBagPopper}
              anchorEl={anchorEl}
              onBackdropClick={onClose}
              key={"my-bag-popper"}
            >
              <MyBagList translationKey={activePopper} onClose={onClose} />
            </HeaderPopper>
            <HeaderPopper
              open={openFavoritesPopper}
              anchorEl={anchorEl}
              onBackdropClick={onClose}
              key={"favorites-popper"}
            >
              <FavoritesList translationKey={activePopper} onClose={onClose} />
            </HeaderPopper>
            <HeaderPopper
              open={openProfilePopper}
              anchorEl={anchorEl}
              onBackdropClick={onClose}
              key={"profile-popper"}
            >
              <ProfileList translationKey={"profile"} onClose={onClose} />
            </HeaderPopper>
          </>
        )}
        {anchorEl && (
          <Hidden smUp>
            <Divider />
          </Hidden>
        )}
      </AppBar>
      {isLoggedIn && (
        <Hidden mdUp>
          <Grid
            className={classes.pointsMobileContainer}
            container
            alignItems="center"
          >
            <Grid item>
              <Points />
            </Grid>
          </Grid>
        </Hidden>
      )}
    </>
  );
};
export default Header;
