import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { Colors, Shadows } from "../../../constants/Style";
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
  btn: {
    // minWidth: "193px",
    border: `1px solid ${Colors.white}`,
    backgroundColor: Colors.white,
    [theme.breakpoints.down("xs")]: {
      maxWidth: "150px",
      minWidth: "auto",
      padding: "12px 15px",
      marginRight: -5,
    },
  },
  mobileBtn: {
    paddingRight: 0,
  },
  fullHeight: {
    height: "100%",
  },
  poppersContent: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: theme.spacing(8),
    alignItems: "center",
    marginRight: -10,
  },
  icon: {
    padding: 0,
    paddingLeft: 18,
    marginLeft: 22,
    height: 35,
    width: 99,
    borderBottom: "2px solid transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "&:first-of-type": {
      marginLeft: 16,
    },
    "&:hover": {
      background: "transparent",
      "& span": {
        background: "rgba(0, 0, 0, 0.04)",
        borderRadius: 50,
        "& span": {
          "& span": {
            background: Colors.basfOrange,
          },
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: 0,
      height: 54,
      width: 18,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingLeft: 0,
      position: "relative",
      marginLeft: 25,
      "& span": {
        "& span": {
          "& svg": {
            width: 20,
            height: 30,
          },
        },
      },
    },
  },
  activeIcon: {
    [theme.breakpoints.down("xs")]: {
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: -3,
        height: 2,
        background: "#fff",
        width: "25px",
        right: -3,
      },
    },
  },
  badgeIcon: {
    "&:after": {
      left: -4,
    },
  },
  badge: {
    background: Colors.basfOrange,
    color: theme.palette.secondary.main,
    top: 4,
    [theme.breakpoints.down("xs")]: {
      height: 13,
      width: 13,
      minWidth: "unset",
      lineHeight: "13px",
      fontSize: "10px",
      top: 7,
    },
  },
  toolbar: {
    backgroundColor: Colors.white,
    zIndex: 1110,
    boxShadow: Shadows.mobileMenu,
    [theme.breakpoints.down("sm")]: {
      height: 56,
    },
  },
  menuGrid: {
    width: "35%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  largeCountBadge: {
    "& .MuiBadge-badge": {
      width: 20,
      height: 20,
      right: "-2px",
    },
  },
  profileBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingLeft: 8,
    paddingRight: 5,
    marginLeft: 12,
    height: "64px",
    cursor: "pointer",
    color: "#fff",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      marginLeft: 14,
      paddingRight: 0,
    },
  },
  profileName: {
    fontSize: "16px",
  },
  pointsMobileContainer: {
    justifyContent: "flex-start",
    padding: theme.spacing(0.75, 0),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(1),
      justifyContent: "space-between",
    },
  },
}));
