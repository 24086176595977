import { CssBaseline, StylesProvider, ThemeProvider } from "@material-ui/core";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { SWROptions } from "../../api/api";
import i18n from "../../i18n";
import { AuthProvider } from "../../state/Auth";
import { CartProvider } from "../../state/Cart";
import BasfTheme from "../../style/themes/BASF";
import BannerForm from "../../components/BannerForm/BannerForm";
import { lazy, Suspense, useEffect, useMemo } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { getCaptchaEnvParams } from "../../utils/Captcha";
import Loader from "../../components/Loader/Loader";
import MetaHead from "../../components/MetaHead/MetaHead";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";
import ScrollToTop from "../../utils/ScrollToTop";

const Routes = lazy(() => import("../Routes/Routes"));

Sentry.init({
  dsn: process?.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process?.env.NODE_ENV,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function App() {
  const { siteKey } = getCaptchaEnvParams();

  const delimiters = useMemo(
    () => [
      {
        delimiter: process?.env.REACT_APP_BG_URL_DELIMITER,
        language: "bg",
      },
    ],
    []
  );

  useEffect(() => {
    const currentHost = window.location.host;
    let includedLanguage: undefined | string = undefined;
    delimiters.forEach((delimiterItem) => {
      if (
        currentHost.includes(delimiterItem.delimiter ?? "762352671235!@#*()")
      ) {
        includedLanguage = delimiterItem.language;
      }
    });
    if (!!includedLanguage) {
      i18n.changeLanguage(includedLanguage);
      localStorage.setItem("basf-lang", includedLanguage);
    }
  }, [delimiters]);

  return (
    <>
      <MetaHead />
      <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
        <ThemeProvider theme={BasfTheme} key="basf-theme">
          <StylesProvider injectFirst={true}>
            <I18nextProvider i18n={i18n}>
              <CssBaseline key="basf-theme" />
              <Router>
                <SWRConfig value={SWROptions}>
                  <ErrorBoundary>
                    <AuthProvider>
                      <CartProvider>
                        <Suspense
                          fallback={<Loader height="20vh" width="100%" />}
                        >
                          <Routes />
                        </Suspense>
                      </CartProvider>
                    </AuthProvider>
                  </ErrorBoundary>
                </SWRConfig>
                <ScrollToTop />
              </Router>
              <BannerForm />
            </I18nextProvider>
          </StylesProvider>
        </ThemeProvider>
      </GoogleReCaptchaProvider>
    </>
  );
}

export default App;
