import {
  Box,
  Button,
  Collapse,
  Container,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useStyles } from "./TopHeader.styles";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import { useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useAuth } from "../../../state/Auth";
import HeaderPopper from "../HeaderPopper/HeaderPopper";
// import LanguagesList from "../LanguagesList/LanguagesList";
import { ActivePopperType } from "../../../types/Components";
import { usePaths } from "../../../constants/routes";
import { useProfileBriefInfo } from "../../../state/Profile";
import Points from "../Points/Points";
import MyBagList from "../MyBagList/MyBagList";
import FavoritesList from "../FavoritesList/FavoritesList";
import ProfileList from "../ProfileList/ProfileList";
import { UserData } from "../../../types/Profile";
import ArrowRotateIcon from "../../ArrowIcon/ArrowIcon";

interface Props {
  handleIconHover: (type: string, hover: boolean) => void;
  activePopper: string;
  hoveredIcon: string;
  // openLanguagePopper: boolean;
  onClose: () => void;
  openMobileMenu: boolean;
  closeExtras: () => void;
  toggleMobileMenu: () => void;
  popperRef: any;
  handleIconClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>,
    type: ActivePopperType
  ) => void;
  anchorEl: any;
  growMenu: boolean;
  userData: UserData;
}

const Top = ({
  handleIconHover,
  activePopper,
  // hoveredIcon,
  // openLanguagePopper,
  onClose,
  handleIconClick,
  anchorEl,
  growMenu,
  // closeExtras,
  // toggleMobileMenu,
  popperRef,
}: // openMobileMenu,
// userData,
Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { isLoggedIn } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const paths = usePaths();
  const { isBasfUser } = useProfileBriefInfo();
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  // const name = useMemo(() => userData?.firstName, [userData?.firstName]);

  const onHomeRedirect = useCallback(() => {
    history.push(isLoggedIn ? paths.home : paths.landing);
  }, [history, paths, isLoggedIn]);

  const onInvoiceGet = useCallback(() => {
    history.push(paths.invoice);
  }, [history, paths.invoice]);

  const openProfilePopper = useMemo(
    () => !!anchorEl && activePopper === "profile",
    [activePopper, anchorEl]
  );

  // const openLanguagePopper = useMemo(
  //   () => !!anchorEl && activePopper === "languages",
  //   [activePopper, anchorEl]
  // );

  const openFavoritesPopper = useMemo(
    () => !!anchorEl && activePopper === "favorites",
    [activePopper, anchorEl]
  );

  const openMyBagPopper = useMemo(
    () => !!anchorEl && activePopper === "bag",
    [activePopper, anchorEl]
  );

  return (
    <Toolbar
      className={classes.root}
      style={{
        zIndex: !isLoggedIn ? undefined : 10,
      }}
    >
      <Container disableGutters>
        <Grid justifyContent="space-between" alignItems="center" container>
          <Logo className={classes.icon} onClick={onHomeRedirect} />
          {isLoggedIn && isBasfUser && (
            <>
              <Collapse in={growMenu} timeout={1100}>
                <Grid item>
                  <Grid justifyContent="flex-end" alignItems="center" container>
                    <Box className={classes.poppersContent}>
                      {isLoggedIn && (
                        <Box
                          className={classes.profileBox}
                          onClick={(e) => handleIconClick(e, "profile")}
                          onMouseEnter={() => handleIconHover("profile", true)}
                          onMouseLeave={() => handleIconHover("profile", false)}
                        >
                          {!isXS && (
                            <Typography
                              variant="h5"
                              className={classes.profileName}
                              onClick={(e) => handleIconClick(e, "profile")}
                            >
                              {t("menu.profile")}
                              <Box display={"inline-flex"} marginLeft={"8px"}>
                                <ArrowRotateIcon
                                  open={openProfilePopper}
                                  isBlue={false}
                                  isWhite={true}
                                />
                              </Box>
                            </Typography>
                          )}
                          {/*<ActionIcon*/}
                          {/*  isActive={*/}
                          {/*    (activePopper === "profile" &&*/}
                          {/*      openProfilePopper) ||*/}
                          {/*    hoveredIcon === "profile"*/}
                          {/*  }*/}
                          {/*  type={"user"}*/}
                          {/*  handleIconClick={(e) =>*/}
                          {/*    handleIconClick(e, "profile")*/}
                          {/*  }*/}
                          {/*  handleHover={() => {}}*/}
                          {/*  badge={false}*/}
                          {/*  noSpacing*/}
                          {/*/>*/}
                        </Box>
                      )}
                      <IconButton
                        id="popper-ref"
                        ref={popperRef}
                        style={{ padding: "24px 0" }}
                      />
                    </Box>
                    {isLoggedIn && (
                      <>
                        <Hidden smDown>
                          <Grid item>
                            <Points />
                          </Grid>
                        </Hidden>
                        <Button
                          color="secondary"
                          variant="contained"
                          startIcon={isMobile ? undefined : <Add />}
                          className={classes.btn}
                          onClick={onInvoiceGet}
                        >
                          {t("buttons.add_invoice")}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Collapse>
              {isLoggedIn && (
                <>
                  <HeaderPopper
                    open={openMyBagPopper}
                    anchorEl={anchorEl}
                    onBackdropClick={onClose}
                    key={"my-bag-popper"}
                  >
                    <MyBagList
                      translationKey={activePopper}
                      onClose={onClose}
                    />
                  </HeaderPopper>
                  <HeaderPopper
                    open={openFavoritesPopper}
                    anchorEl={anchorEl}
                    onBackdropClick={onClose}
                    key={"favorites-popper"}
                  >
                    <FavoritesList
                      translationKey={activePopper}
                      onClose={onClose}
                    />
                  </HeaderPopper>
                  <HeaderPopper
                    open={openProfilePopper}
                    anchorEl={anchorEl}
                    onBackdropClick={onClose}
                    key={"profile-popper"}
                  >
                    <ProfileList translationKey={"profile"} onClose={onClose} />
                  </HeaderPopper>
                </>
              )}
            </>
          )}
          {/*{!isLoggedIn && (*/}
          {/*  <>*/}
          {/*    <ActionIcon*/}
          {/*      isActive={*/}
          {/*        (activePopper === "languages" && openLanguagePopper) ||*/}
          {/*        hoveredIcon === "languages"*/}
          {/*      }*/}
          {/*      type={"languages"}*/}
          {/*      handleIconClick={(e) => handleIconClick(e, "languages")}*/}
          {/*      handleHover={(hover) => handleIconHover("languages", hover)}*/}
          {/*      badge={false}*/}
          {/*    />*/}
          {/*    <HeaderPopper*/}
          {/*      open={openLanguagePopper}*/}
          {/*      anchorEl={anchorEl}*/}
          {/*      onBackdropClick={onClose}*/}
          {/*      key="languages-popper"*/}
          {/*    >*/}
          {/*      <LanguagesList translationKey={"languages"} onClose={onClose} />*/}
          {/*    </HeaderPopper>*/}
          {/*  </>*/}
          {/*)}*/}
        </Grid>
      </Container>
    </Toolbar>
  );
};

export default Top;
