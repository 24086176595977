import {
  Box,
  Divider,
  Hidden,
  ListItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../../../constants/languages";
import { useStyles } from "./LanguagesList.styles";
import { LanguagesListPropsType } from "../../../types/Components";
import NavPopperTitle from "../../NavPopper/NavPopperTitle/NavPopperTitle";
import clsx from "clsx";

const LanguagesList: React.FC<LanguagesListPropsType> = ({
  onClose,
  translationKey,
}) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const getLanguageEnvParams = useCallback((key: "bg") => {
    let redirectUrl: string | undefined = undefined;
    let delimiter: string | undefined = undefined;
    switch (key) {
      case "bg":
        redirectUrl = process?.env.REACT_APP_BG_REDIRECT_URL;
        delimiter = process?.env.REACT_APP_BG_URL_DELIMITER;
        break;
    }
    return { redirectUrl, delimiter };
  }, []);

  const onLanguageHandler = useCallback(
    (key: "bg") => {
      const { redirectUrl, delimiter } = getLanguageEnvParams(key);
      if (redirectUrl && delimiter) {
        window.location.href = `${redirectUrl}`;
      } else {
        i18n.changeLanguage(key);
      }
      onClose();
    },
    [getLanguageEnvParams, i18n, onClose]
  );

  const languagesMenuItems = useMemo(
    () =>
      languages.map(({ key }) => {
        return (
          <ListItem
            key={key}
            className={classes.item}
            disableGutters
            onClick={() => onLanguageHandler(key)}
          >
            <Typography
              variant="body1"
              className={clsx(classes.itemText, {
                [classes.active]: key === i18n.language,
              })}
            >
              {t(`languages.${key}`)}
            </Typography>
          </ListItem>
        );
      }),
    [
      classes.active,
      classes.item,
      classes.itemText,
      i18n.language,
      onLanguageHandler,
      t,
    ]
  );

  return (
    <Box className={classes.root}>
      <Hidden xsDown>
        <Divider />
      </Hidden>
      <NavPopperTitle translationKey={translationKey} disable />
      <Hidden xsDown>
        <Divider />
      </Hidden>
      <MenuList className={classes.list}>{languagesMenuItems}</MenuList>
    </Box>
  );
};
export default LanguagesList;
