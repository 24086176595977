import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationBG from "./locales/bg.json";

i18n.use(initReactI18next).init({
  resources: {
    bg: {
      translations: translationBG,
    },
  },
  compatibilityJSON: "v2",
  fallbackLng: localStorage.getItem("basf-lang") || "bg",
  debug: false,
  lng: localStorage.getItem("basf-lang") || "bg",
  ns: ["translations"],
  defaultNS: "translations",
  react: {
    useSuspense: true,
  },
});

export default i18n;
